import React, { useState } from "react";

const SelectableTags = ({ tags, onSelectionChange }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  const toggleTagSelection = (selectedTag) => {
    let updatedSelection;
    if (selectedTags.find((t) => t.tag.name === selectedTag.tag.name)) {
      // Unselecting the tag
      updatedSelection = selectedTags.filter((t) => t.tag.name !== selectedTag.tag.name);
    } else {
      // Selecting the tag
      updatedSelection = [...selectedTags, selectedTag];
    }
    setSelectedTags(updatedSelection);
    onSelectionChange(updatedSelection); // Call the callback with the updated selection
  };

  return (
    <div className="flex overflow-x-auto space-x-2 p-2">
      {tags.map((tagObject, index) => {
        const { name } = tagObject.tag;
        const { count } = tagObject;

        return (
          <div
            key={index}
            className={`cursor-pointer px-4 py-2 rounded whitespace-nowrap ${
              selectedTags.find((t) => t.tag.name === name)
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => toggleTagSelection(tagObject)}
          >
            {name} <span className="text-sm text-gray-500">({count})</span>
          </div>
        );
      })}
    </div>
  );
};

export default SelectableTags;