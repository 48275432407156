const getIconForLink = (link) => {
  const hostnameParts = new URL(link).hostname.split('.');
  const secondLevelDomain = hostnameParts[1];
  let iconFile = "/wiki_icon.png";
  if (secondLevelDomain === 'steampowered') {
    iconFile = "/steam_icon.png";
  } else if (secondLevelDomain == 'imdb') {
    iconFile = "/imdb_icon.png"
  } else if (secondLevelDomain == 'apple') {
    iconFile = "/applebooks_icon.png"
  }
  return iconFile;
}
  
export default getIconForLink;