import React, { useContext, useState } from 'react';
import {localizeKey, localizeArt} from './../localize.js';
import MyUserContext from './../myUserContext.js';

function LinkInput({ setOutsideTextInput, text, isErr, bigLinksOnTop }) {
  const myUser = useContext(MyUserContext);

  const [linkInputText, setLinkInputText] = useState("");

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <>
      {
      bigLinksOnTop &&
      <div>        
        <div class="flex justify-center items-center space-x-3">
          <a href={language === "ru" ? "https://ru.wikipedia.org" : "https://en.wikipedia.org" } target="_blank" rel="noopener noreferrer" className="flex items-center">
            <img
              src="/wiki_icon.png"
              alt="Wikipedia"
              className="max-w-8 max-h-8 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
            />
          </a>

          <a href="https://imdb.com/" target="_blank" rel="noopener noreferrer" className="flex items-center">
            <img
              src="/imdb_icon.png"
              alt="Imdb"
              className="max-w-12 max-h-12 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
            />
          </a>

          <a href={language === "ru" ? "https://www.google.ru/search?q=apple+books+YOUR_BOOK_TITLE" : "https://www.google.com/search?q=apple+books+YOUR_BOOK_TITLE" } target="_blank" rel="noopener noreferrer" className="flex items-center">
            <img
              src="/applebooks_icon.png"
              alt="Apple books"
              className="max-w-12 max-h-12 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
            />
          </a>

          <a href="https://store.steampowered.com/" target="_blank" rel="noopener noreferrer" className="flex items-center">
            <img
              src="/steam_icon.png"
              alt="Steam"
              className="max-w-12 max-h-12 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
            />
          </a>
        </div>
      </div>
      }

    <div class="flex space-x-0.5 max-h-10 my-2 items-center justify-center">
      {
      !bigLinksOnTop &&
      <>
      <a href={language === "ru" ? "https://ru.wikipedia.org" : "https://en.wikipedia.org" } target="_blank" rel="noopener noreferrer" className="flex items-center">
        <img
          src="/wiki_icon.png"
          alt="Wikipedia"
          className="max-w-8 max-h-8 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
        />
      </a>

      <a href="https://imdb.com/" target="_blank" rel="noopener noreferrer" className="flex items-center">
        <img
          src="/imdb_icon.png"
          alt="Imdb"
          className="max-w-12 max-h-12 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
        />
      </a>

      <a href={language === "ru" ? "https://www.google.ru/search?q=apple+books+com+НАЗВАНИЕ_КНИГИ" : "https://www.google.com/search?q=apple+books+com+YOUR_BOOK_TITLE" } target="_blank" rel="noopener noreferrer" className="flex items-center">
        <img
          src="/applebooks_icon.png"
          alt="Apple books"
          className="max-w-8 max-h-8 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
        />
      </a>

      <a href="https://store.steampowered.com/" target="_blank" rel="noopener noreferrer" className="flex items-center">
        <img
          src="/steam_icon.png"
          alt="Steam"
          className="max-w-8 max-h-8 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
        />
      </a>
      </>
      }

      <input type="search" id="website" class={(isErr ? "border-red-500 " : "border-gray-300 ") + "my-2 bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 center-placeholder"} placeholder={localizeKey('wikiLinkHint', language)} 
        onChange={
          (e) => {
            setLinkInputText(e.target.value);
            setOutsideTextInput(e.target.value);
          }
        } 
        value={text} required
      />
    </div>
    </>
  );
}

export default LinkInput;