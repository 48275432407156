import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import { Link } from 'react-router-dom';
import ShareButton from './ShareButton';
import LinkInput from './LinkInput';
import ExpandableImage from './ExpandableImage';

const TextUserPair = ({user, userId, hideRecomInput}) => {
  const myUser = useContext(MyUserContext);

  const [curUser, setCurUser] = useState(user);

  const [inputText, setInputText] = useState('');

  // Status of recent recommendation.
  const [errText, setErrText] = useState(null);

  const [successText, setSuccessText] = useState(null);
  const [successResp, setSuccessResp] = useState(null);

  const fetchCurUser = () => {
    axiosInstance
    .get(`/users/${userId}`)
    .then((response) => {
      setCurUser(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const handleRecommend = (userId, link) => {
    axiosInstance
    .post('/postRecommendation', { toUserId: userId, wikiArticleLink: link })
    .then((response) => {
      setSuccessText(localizeKey('recommendationPostSuccess', language));
      setErrText(null);
      setSuccessResp(response.data);
      setInputText("");
    })
    .catch((error) => {
      setErrText(error.response.data);
      setSuccessText(null);
      setSuccessResp(null);
    });
  }

  const handleFollow = () => {
    let verb = "follow";
    if (curUser.followedByMeState === "approved" || curUser.followedByMeState === "requested") {
      verb = "unfollow";
    }
    axiosInstance
    .post(`users/${curUser.id}/${verb}`, {})
    .then((response) => {
      setCurUser(response.data);
    })
    .catch((error) => {
      console.error(error);
    })
  }
  const renderButtonTextLocalizeKey = (followedByMeState) => {
    switch(followedByMeState) {
      case 'none':
        return 'follow';
      case 'requested':
        return 'requested';
      case 'approved':
        return 'approved';
      default:
        console.log.error("undefined followedByMeState");
        return "undefined";
    }
  }

  const handleApproveFollow = () => {
    axiosInstance
    .post(`users/${curUser.id}/respondToFollowRequest`, { decision : "approve" })
    .then((response) => {
      setCurUser(response.data);
    })
    .catch((error) => {
      console.error(error);
    })
  }
  const handleRejectFollow = () => {
    axiosInstance
    .post(`users/${curUser.id}/respondToFollowRequest`, { decision : "reject" })
    .then((response) => {
      setCurUser(response.data);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  if (!curUser) {
    fetchCurUser();
    return <div></div>;
  }

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <div class="container mx-auto flex justify-center items-center flex-col">
      <div className="my-3 min-w-0 w-full lg:w-1/2 gap-x-4">
        <div class="flex">
          <Link to={`/users/${curUser.id}/likes`}>
            <ExpandableImage src={curUser.avatarUrl} />
          </Link>
          &nbsp;&nbsp;
          <div class="-mt-1.5">
            <Link class="flex" to={`/users/${curUser.id}/likes`}>
              <p className="text-base font-semibold leading-6 text-gray-900 hover:underline min-w-min">
                {curUser.userName + (myUser && myUser.myUserInfo.myUserId === curUser.id ? " (me)" : "")}                
              </p>
              {
              curUser.followMeState === "approved" &&
                <>&nbsp;({ language==="ru" ? "подписан(а) на вас" : "follows you"})</>
              }
            </Link>
            <div onClick={handleFollow} class={(curUser.followedByMeState === "approved" || curUser.followedByMeState === "requested") ? "text-blue-700 " + "inline-block w-30 px-2 flext justify-center items-center max-h-7 text-center border-2 border-blue-700 rounded-lg hover:bg-slate-200 hover:border-blue-800 cursor-pointer" : "bg-blue-700 text-white " + "inline-block w-30 px-2 flext justify-center items-center max-h-7 text-center border-2 border-blue-700 rounded-lg hover:bg-blue-800 hover:border-blue-800 cursor-pointer"}>
              {localizeKey(renderButtonTextLocalizeKey(curUser.followedByMeState), language)}
            </div>
          </div>
          <div class="-mt-1.5 ml-5 md:ml-15">
            {
            curUser.followMeState === "requested" &&
            <>
              <div>{language === "ru" ? "Хочет подписаться" : "Wants to follow"}</div>
              <div class="flex">
                <div onClick={handleApproveFollow} class={"bg-blue-700 text-white " + "px-2 flext justify-center items-center max-w-31 max-h-7 text-center border-2 border-blue-700 rounded-lg hover:bg-blue-800 hover:border-blue-800 cursor-pointer"}>
                  Approve
                </div>              
                <div onClick={handleRejectFollow} class={"text-blue-700 " + "px-2 flext justify-center items-center max-w-31 max-h-7 text-center border-2 border-blue-700 rounded-lg hover:bg-slate-200 hover:border-blue-800 cursor-pointer"}>
                    Reject
                </div>
              </div>
            </>
            }
          </div>
        </div>      
        {
        !hideRecomInput &&
        <div>
          {
            myUser && myUser.myUserInfo.myUserId !== curUser.id &&
            <>
              <div class="mb-1">
                <LinkInput setOutsideTextInput={setInputText} text={inputText} />
              </div>
              {
              /*
              <div class="-mt-2 mb-1 text-xs flex justify-center">
                {"(" + localizeKey('wikiMustHaveEnglishVersion', language) + ")"}
              </div>
              */
              }
              <button type="submit" onClick={()=>{handleRecommend(curUser.id, inputText);}} class="mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">{localizeKey('recommend', language)}</button>
            </>
          }
        </div>
        }

        {errText && <p style={{ color: 'red' }}> {errText} </p>}
        {successText &&
          <>
            <div class="text-center text-green-900">
              <div> {successText} </div>
              <Link to={"/recommendations/" + successResp.recommendation.id}>
                <div class="-mt-1 text-blue-500 hover:text-blue-800 cursor-pointer"> Перейти </div>
              </Link>
            </div>
            {false &&
              <div class="flex justify-center">
                <ShareButton
                  url={
                    "https://wowcontent.lol/recommendations/" + successResp.recommendation.id
                  }
                  title={
                    localizeArt(successResp.art).name
                  }
                  text={
                    localizeKey('shareRecomText', language) + ': ' + localizeArt(successResp.art).name
                  }
                  overrideButtonText={
                    localizeKey('shareRecomNotMiss', language)
                  }
                />
              </div>
            }
          </>
        }
      </div>
    </div>
  )
};

export default TextUserPair;