import React, { useContext, useState } from 'react';
import {localizeKey, localizeArt} from './../localize.js';
import MyUserContext from './../myUserContext.js';

function CopyTextButton({ textToCopy, overrideButtonText }) {
  const myUser = useContext(MyUserContext);

  const initialButtonText = () => {
    const language = myUser ? myUser.myUserSettings.lang : 'ru';
    return overrideButtonText ? overrideButtonText : localizeKey('copy', language);
  }

  const [buttonText, setButtonText] = useState( initialButtonText() );

  const handleClick = async () => {
    navigator.clipboard.writeText(textToCopy);
    const language = myUser ? myUser.myUserSettings.lang : 'ru';
    setButtonText(initialButtonText() + " ✔");
    setTimeout(() => {
      setButtonText(initialButtonText());
    }, 600);    
  };

  return (
    <button class="text-blue-500 hover:text-blue-800" onClick={handleClick}>
      {
        buttonText
      }
    </button>
  );
}

export default CopyTextButton;