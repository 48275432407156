import React, { useState, useEffect } from 'react';

const DotSpinner = () => {
  const [dotCount, setDotCount] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setDotCount((prevCount) => (prevCount % 3) + 1);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return <div>{'.'.repeat(dotCount)}</div>;
};

export default DotSpinner;