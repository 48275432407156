import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import CardEventArtChat from './CardEventArtChat';
import LinkInput from './LinkInput';
import TextUserPair from './TextUserPair';
import SelectableTags from './SelectableTags';
import { Link } from 'react-router-dom';

const PersonalArtEvents = ({eventType, userId}) => {
  const myUser = useContext(MyUserContext);
  const [finishedInput, setFinishedInput] = useState("");
  const [finishedEvents, setFinishedEvents] = useState(null);

  const [hasAccess, setHasAccess] = useState(true);

  const { pathUserId } = useParams();
  const effectiveUserId = () => {
    // UserID from props or path param.
    let effectiveUserId = userId;
    if (!effectiveUserId) {
      effectiveUserId = pathUserId;
    }
    return effectiveUserId;
  }

  const initEventsFetchUrl = `/artEvents/${effectiveUserId()}/${eventType}`;
  const [nextUrl, setNextUrl] = useState(initEventsFetchUrl);
  const [loading, setLoading] = useState(false);
  const observer = useRef();

  const [fetching, setFetching] = useState(false);

  const [curUser, setCurUser] = useState(null); // some other user
  const fetchCurUser = () => {
    axiosInstance
    .get(`/users/${effectiveUserId()}`)
    .then((response) => {
      setCurUser(response.data);
    })
    .catch((error) => {
      console.error(error);      
    });
  }

  const [relevantTags, setRelevantTags] = useState(null);
  const fetchRelevantTagNames = (alreadySetTags=[]) => {
    const tagNames = alreadySetTags.map((t) => { return t.tag.name; });
    const tagParams = tagNames.map((n) => { return "alreadySetTags="+n; }).join("&");
    axiosInstance
    .get(`/relevant/tags?type=${eventType}&${tagParams}`)
    .then((response) => {
      setRelevantTags(response.data);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  const [unfinishedRecomsCount, setUnfinishedRecomsCount] = useState(null);
  const fetchUnfinishedRecomsCount = () => {
    axiosInstance
    .get('/recommendations/unfinished/count')
    .then((response) => {
      setUnfinishedRecomsCount(response.data.unfinished);
    })
    .catch((error) => {
      console.error(error.message);
    })
  }

  const fetchFinishedEvents = (nextUrl) => {
    //alert(nextUrl);
    if (!nextUrl || loading) {
      return;
    }
    setLoading(true);
    axiosInstance
    .get(nextUrl)
    .then((response) => {
      // setFinishedEvents(response.data.data);
      if (finishedEvents === null) {
        setFinishedEvents(response.data.data);
      } else {
        setFinishedEvents(prevObjects => [...prevObjects, ...response.data.data]);  
      }
      setNextUrl(response.data.nextPageUrl);
    })
    .catch((error) => {
      if (error.response) {
        // Server responded with a status other than 200 range
        if (error.response.status === 403) {
          // Handle 403 Forbidden
          setHasAccess(false);
          console.log("Setting has access to false");
        } else {
          console.error('Error: ', error.response.data);
        }
      } else {
        console.error(error);  
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleFinishedInputChange = (event) => {
    setFinishedInput(event.target.value);
  };
  const handleFinished = () => {
    axiosInstance
    .post(`/artEvents`, { wikiArticleLink: finishedInput, type: eventType })
    .then((response) => {
      if (finishedEvents === null) {
        setFinishedEvents(response.data);
      } else {
        setFinishedEvents(prevObjects => [response.data, ...prevObjects]);  
      }
      setFinishedInput("");
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const lastObjectRef = useCallback(node => {
    if (loading) return;

    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        fetchFinishedEvents(nextUrl);
      }
    });
    if (node) {
      observer.current.observe(node);
    }
  }, [loading, nextUrl, fetchFinishedEvents]);

  const curUserIsMe = () => {
    return (myUser && effectiveUserId() === myUser.myUserInfo.myUserId);
  }

  if (hasAccess && (finishedEvents === null || (!curUserIsMe() && curUser === null))) {
    if (fetching === false) {
      setFetching(true);
      fetchFinishedEvents(nextUrl);
      fetchUnfinishedRecomsCount();
      if (!curUserIsMe()) {
        fetchCurUser();
      }
    }
    return (
      <div></div>
    )
  } else {
    // setFetching(false); // Needed to comment to avoid re-render, wtf
  }

  if (relevantTags === null) {
    fetchRelevantTagNames();  
  }

  const language = myUser !== null ? myUser.myUserSettings.lang : 'ru';

  let buttonTextLocalizeKey = "addFinished";
  if (eventType === "like") {
    buttonTextLocalizeKey = "like";
  } else if (eventType === "todo") {
    buttonTextLocalizeKey = "addTodo";
  }

  // console.log("finishedEvents[0].event.chatId: " + finishedEvents[0].event.chatId);

  // Links to other types of events: start
  // TODO: move it to a separate user page component.
  const likesLink = `/users/${effectiveUserId()}/likes`;
  const classLikes = (eventType === "like" ? "underline" : "");

  const todosLink = `/users/${effectiveUserId()}/todos`;
  const classTodos = (eventType === "todo" ? "underline" : "");

  const finishedLink = `/users/${effectiveUserId()}/finished`;
  const classFinished = (eventType === "finished" ? "underline" : "");

  const recommendationsLink = `/recommendations/toMe`;
  const classRecommendations = (eventType === "recommendation" ? "underline" : ""); // yet, it's never recommendation since PersonalArtEvents is not used for recoms
  // Links to other types of events: end

  return (
    <div class="container mx-auto px-4 md:px-8 w-full lg:w-1/2">
      <div class="container mx-auto">
        {
          myUser && effectiveUserId() !== myUser.myUserInfo.myUserId ?
            <TextUserPair user={curUser} userId={effectiveUserId()} hideRecomInput={true}/> :
            <>
              <br/>
              <div class="flex justify-center">
                <div class={classRecommendations}>
                  <Link class="text-blue-500 hover:text-blue-800" to={recommendationsLink}>
                    {localizeKey('personalRecommendations', language)}
                    {(unfinishedRecomsCount && unfinishedRecomsCount > 0) ? " (" + unfinishedRecomsCount + ")" : ""}
                  </Link>
                </div>
                <br/>
                <br/>
              </div>
            </>
        }
        <div class="flex justify-center space-x-4">
          <div class={classLikes}>
            <Link class="text-blue-500 hover:text-blue-800" to={likesLink}>
              {localizeKey('likes', language)}
            </Link>
          </div>
          <div class={classTodos}>
            <Link class="text-blue-500 hover:text-blue-800" to={todosLink}>
              {localizeKey('todos', language)}
            </Link>
          </div>
          <div class={classFinished}>
            <Link class="text-blue-500 hover:text-blue-800" to={finishedLink}>
              {localizeKey('finisheds', language)}
            </Link>
          </div>
        </div>
      </div>
      {
      (hasAccess === false) ?
        <div class="flex justify-center text-center">
          {language === "ru" ? "Приватный аккаунт, подпишитесь на пользователя и дождитесь подтверждения" : "Account is private, subscribe to user and wait for approval"}
        </div> 
        :
          <>
            {curUserIsMe() &&
            <div class="flex justify-center">
              <SelectableTags tags={relevantTags !== null ? relevantTags : []} onSelectionChange={(tags) => {
                // alert(tags);
                const tagParams = tags.map((t) => { return "tags="+t.tag.name+"&"; }).join("");
                const eventsFetchUrl = `/artEvents/${effectiveUserId()}/${eventType}?${tagParams}`;
                // alert(eventsFetchUrl);
                fetchRelevantTagNames(tags);
                // Oh it's so ugly!
                setFinishedEvents([]); // not null intentionally, heh
                //setNextUrl(eventsFetchUrl);
                //setFetching(false);
                //setFinishedEvents(null);
                // Problem here: next url is not updated before the fetchFinishedEvents call.
                fetchFinishedEvents(eventsFetchUrl);
              }} />
            </div>
            }

            <>
              <ul className="container mx-auto divide-y divide-gray-100">
                {finishedEvents.map(
                  (artEvent, index) => {
                    if (index === finishedEvents.length - 1) {              
                      return (
                        <li ref={lastObjectRef}>
                          <CardEventArtChat key={artEvent.event.id} userInfo={{name: artEvent.eventOwnerUserName, avatarUrl: artEvent.eventOwnerUserAvatarUrl}} event={artEvent.event} chatInfo={artEvent.chat} eventUserchatInfo={artEvent.chat} eventType={eventType} art={artEvent.art} language={language} />
                        </li>
                      )
                    } else {
                      return (
                        <li>
                          <CardEventArtChat key={artEvent.event.id} userInfo={{name: artEvent.eventOwnerUserName, avatarUrl: artEvent.eventOwnerUserAvatarUrl}} event={artEvent.event} chatInfo={artEvent.chat} eventType={eventType} art={artEvent.art} language={language} />
                        </li>
                      )
                    }
                  }
                )}
              </ul>
            </>
          </>
      }
      
    </div>
  );
}

export default PersonalArtEvents;