import { useNavigate } from "react-router-dom";
import axiosInstance from './axiosInstance.js';

export const fetchOauthLoginLink = (urlCallback) => {
  axiosInstance
  .get(`/getOauthLoginLink`)
  .then((response) => {
    if (response.status === 200) {
      urlCallback(response.data.url);
    } else {
      throw new Error('Failed to fetch oauth login link');
    }
  })
  .catch(error => {
    console.error('Error getting oauth login link:', error);
  });
};
             
export const OauthCallbackHandler = ({onJWTObtained}) => {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  if (!code) {
    console.error("Expected but did not get code from google");
    return
  }

  axiosInstance
  .post(`/provideOauthCode`, { code: code })
  .then((response) => {
    const jwtToken = response.data.token;
    localStorage.setItem('jwtToken', jwtToken);
    onJWTObtained();
    console.log("Navigating from oauth to /");
    navigate("/");
  })
  .catch((error) => {
    // /setError(error.message); // Set error state with error message
    console.error("Could not exchange oauth code with jwt token from backend");
  });

  return (
    <div>Exchanging oauth code with jwt token from backend...</div>
  )
};