import React, { useState, useRef, useContext } from 'react';
// import heic2any from 'heic2any';
import axiosInstance from './../axiosInstance.js';
import ExpandableImage from './ExpandableImage.js';
import MyUserContext from './../myUserContext.js';
import {localizeKey, localizeArt} from './../localize.js';

function AvatarUpload({initUrl, onAvatarUrlChange}) {
  const myUser = useContext(MyUserContext);

  const fileInputRef = useRef(null);
  const [url, setUrl] = useState(initUrl);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadingFailed, setUploadingFailed] = useState(false);
  //const [imageRenderFinished, setImageRenderFinished] = useState(true); // in the beginning, let it be true even if img is not loaded yet

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const scaleDownImage = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        let imgSrc = event.target.result;

        // if (file.type === 'image/heic' || file.type === 'image/heif') {
        //   try {
        //     const conversionResult = await heic2any({ blob: file, toType: 'image/jpeg' });
        //     imgSrc = URL.createObjectURL(conversionResult);
        //   } catch (error) {
        //     reject(error);
        //     return;
        //   }
        // }

        const img = new Image();
        img.onload = () => {
          const MAX_SIZE = 800; // Set your desired max size here
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_SIZE) {
              height *= MAX_SIZE / width;
              width = MAX_SIZE;
            }
          } else {
            if (height > MAX_SIZE) {
              width *= MAX_SIZE / height;
              height = MAX_SIZE;
            }
          }

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const scaledFile = new File([blob], file.name.replace(/\.[^/.]+$/, '.jpg'), {
              type: 'image/jpeg', // Adjust the type if needed
              lastModified: Date.now(),
            });
            resolve(scaledFile);
          }, 'image/jpeg', 0.7); // Adjust the quality as needed
        };
        img.src = imgSrc;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileInputChange = async (event) => {
    setIsUploading(true);
    setUploadingFailed(false);

    const selectedFile = event.target.files[0];
    const scaledFile = await scaleDownImage(selectedFile);

    const formData = new FormData();
    formData.append('file', scaledFile);

    axiosInstance
    .post('/users/avatars/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      setUrl(response.data.avatarUrl);
      onAvatarUrlChange(response.data.avatarUrl);
      setIsUploading(false);
      setUploadingFailed(false);
    })
    .catch((error) => {
      setUploadingFailed(true);
      setIsUploading(false);
      alert(error.message);
    });
  };

  const language = myUser !== null ? myUser.myUserSettings.lang : 'ru';

  return (
    <div class="flex justify-center items-center">
      {
        !isUploading && 
          <>
            <ExpandableImage src={url} />
            &nbsp;
          </>
      }
      <input
        type="file"        
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />
      <label onClick={handleButtonClick} style={{ cursor: 'pointer', padding: '10px 20px', border: '1px solid #ccc', borderRadius: '5px' }}>
        {
          isUploading && "Uploading, wait..."
        }
        {
          uploadingFailed && <span class="text-red-500">Uploading failed</span>
        }
        {
          !isUploading && !uploadingFailed && localizeKey('uploadAvatar', language)
        }
      </label>
    </div>
  );
}

export default AvatarUpload;
