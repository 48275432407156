import React, { useState, useEffect, useRef } from "react";
import axiosInstance from './../axiosInstance.js';

const TagsInput = ({ initialTags = [], onTagsChange }) => {
  const [tags, setTags] = useState(initialTags); // Initialize with array of strings
  const [searchResults, setSearchResults] = useState([]); // For auto-complete
  const [searchQuery, setSearchQuery] = useState(""); // Input field query
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // Manage dropdown visibility

  const inputRef = useRef(null); // Ref for the input element
  const dropdownRef = useRef(null); // Ref for the dropdown menu

  const addTag = (tag) => {
    if (!tags.includes(tag)) { // Check if tag is already included
      const updatedTags = [...tags, tag];
      setTags(updatedTags);
      onTagsChange(updatedTags); // Notify the parent of updated tags
    }
  };

  const removeTag = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
    onTagsChange(updatedTags); // Notify the parent of updated tags
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (query.length > 0) {
      try {
        const response = await axiosInstance.get(`/relevant/tags/search?query=${query}`);
        setSearchResults(response.data.map(item => item.name));
        setIsDropdownVisible(true); // Show the dropdown when there are results
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    } else {
      setSearchResults([]); // Clear search results if input is empty
      setIsDropdownVisible(false); // Hide the dropdown if there are no results
    }
  };

  const handleAddTag = (tag) => {
    addTag(tag);
    setSearchQuery(""); // Clear the search input after adding
    setSearchResults([]); // Clear the search results after adding
    setIsDropdownVisible(false); // Hide the dropdown after adding
  };

  const handleInputChange = (e) => {
    handleSearch(e.target.value);
  };

  const handleDropdownItemClick = (tag) => {
    handleAddTag(tag);
  };

  const handleClickOutside = (event) => {
    // Hide the dropdown if clicking outside
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setIsDropdownVisible(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the component
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative px-4 py-2">
      {/* Display the list of added tags */}
      <div className="flex flex-wrap gap-1 mb-1">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="cursor-pointer px-2 py-1 rounded bg-gray-200"
          >
            {tag}
            <button
              className="ml-2 text-sm text-gray-500"
              onClick={() => removeTag(tag)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>

      {/* Search Results Dropdown */}
      {isDropdownVisible && searchResults.length > 0 && (
        <div
          ref={dropdownRef}
          className="absolute top-full left-0 right-0 bg-gray-100 border border-gray-300 rounded mb-2 max-h-40 overflow-y-auto z-10"
        >
          {searchResults.map((tag, index) => (
            <div
              key={index}
              className="p-2 cursor-pointer hover:bg-gray-200"
              onClick={() => handleDropdownItemClick(tag)}
            >
              {tag}
            </div>
          ))}
        </div>
      )}

      {/* Search Input */}
      <input
        ref={inputRef}
        type="text"
        className="w-full border border-gray-300 rounded p-1"
        placeholder="Type to search tags..."
        value={searchQuery}
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && searchQuery.trim() !== '') {
            handleAddTag(searchQuery.trim()); // Add tag on Enter key press
          }
        }}
        onFocus={() => setIsDropdownVisible(true)} // Show dropdown when input is focused
      />
    </div>
  );
};

export default TagsInput;
