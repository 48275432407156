import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import CardEventArtChat from './CardEventArtChat';
import PersonalArtEvents from './PersonalArtEvents';
import { Link } from 'react-router-dom';

const UserPage = ({ userId }) => {
  const myUser = useContext(MyUserContext);

  const { pathUserId } = useParams();
  const effectiveUserId = () => {
    // UserID from props or path param.
    let effectiveUserId = userId;
    if (!effectiveUserId) {
      effectiveUserId = pathUserId;
    }
    return effectiveUserId;
  }

  const language = myUser !== null ? myUser.myUserSettings.lang : 'ru';

  const likesLink = `/users/${effectiveUserId()}/likes`;
  const todosLink = `/users/${effectiveUserId()}/todos`;
  const finishedLink = `/users/${effectiveUserId()}/finished`;

  return (
    <>
      <div>
        <Link class="text-blue-500 hover:text-blue-800" to={likesLink}>
          Likes
        </Link>
        <Link class="text-blue-500 hover:text-blue-800" to={todosLink}>
          Todo
        </Link>
        <Link class="text-blue-500 hover:text-blue-800" to={finishedLink}>
          Finished
        </Link>
      </div>

      <PersonalArtEvents eventType="like" key="someonesLikes" userId={effectiveUserId()} />
    </>
  )
}

export default UserPage;