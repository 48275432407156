// ToggleSwitch.js
import React, { useState } from 'react';

const ToggleSwitch = ({ isOn, onToggle }) => {
  const [isToggled, setIsToggled] = useState(isOn);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    onToggle(!isToggled);
  };

  return (
    <div
      onClick={handleToggle}
      className={`w-12 h-6 md:w-16 md:h-8 flex items-center rounded-full p-1 cursor-pointer ${
        isToggled ? 'bg-blue-500' : 'bg-gray-300'
      }`}
    >
      <div
        className={`bg-white w-5 h-5 md:w-6 md:h-6 rounded-full shadow-md transform duration-300 ease-in-out ${
          isToggled ? 'translate-x-5 md:translate-x-8' : 'translate-x-0'
        }`}
      ></div>
    </div>
  );
};

export default ToggleSwitch;
