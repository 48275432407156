import React from 'react';

const Modal = ({ imageUrl, closeModal }) => {
  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleClickOutside}
    >
      <img
        src={imageUrl}
        alt="Modal"
        className="max-w-full max-h-screen object-contain"
        onClick={closeModal}
      />
      <button
        className="absolute top-0 right-0 m-4 text-white text-lg"
        onClick={closeModal}
      >
        
      </button>
    </div>
  );
};

export default Modal;
