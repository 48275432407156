import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import { Link } from 'react-router-dom';

const NewUser = ({user}) => {
  const navigate = useNavigate();

  const getTokenOfNewUser = () => {
    axiosInstance
    .post('/localhost/getNewUserToken', {})
    .then((response) => {
      const jwtToken = response.data.token;
      localStorage.setItem('jwtToken', jwtToken);
      navigate("/");
    })
    .catch((error) => {
      console.log(error);
    });
  }

  getTokenOfNewUser();

  return (
    <div>Loading...</div>
  )
};

export default NewUser;