import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import CardEventArtChat from './CardEventArtChat';
import LinkInput from './LinkInput';
import TextUserPair from './TextUserPair';
import { Link } from 'react-router-dom';

// TODO: rename to UserMainPage
const LikeEvents = ({ userId }) => {
  const myUser = useContext(MyUserContext);
  const { pathUserId } = useParams();
  const [likeInput, setLikeInput] = useState("");

  // Displayed likes and user.
  const [likeEvents, setLikeEvents] = useState(null);
  const [publicTodoEvents, setPublicTodoEvents] = useState(null);
  const [curUser, setCurUser] = useState(null);

  const effectiveUserId = () => {
    // UserID from props or path param.
    let effectiveUserId = userId;
    if (!effectiveUserId) {
      effectiveUserId = pathUserId;
    }
    return effectiveUserId;
  }

  const fetchLikeEvents = () => {
    axiosInstance
    .get(`/getLikes?userId=${effectiveUserId()}`)
    .then((response) => {
      setLikeEvents(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const fetchPublicTodoEvents = () => {
    axiosInstance
    .get(`/getTodos?userId=${effectiveUserId()}`)
    .then((response) => {
      setPublicTodoEvents(response.data);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  const fetchCurUser = () => {
    axiosInstance
    .get(`/users/${effectiveUserId()}`)
    .then((response) => {
      setCurUser(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const handleLikeInputChange = (event) => {
    setLikeInput(event.target.value);
  };
  const handleLike = () => {
    axiosInstance
    .post(`/postLike`, { wikiArticleLink: likeInput })
    .then((response) => {
      fetchLikeEvents();
    })
    .catch((error) => {
      console.error(error);
    });
  }

  // TODO: do not request curUser if it's 'my' page.
  if (likeEvents === null && curUser === null && publicTodoEvents === null) {
    fetchLikeEvents();
    fetchCurUser();
    fetchPublicTodoEvents();
  }

  if (likeEvents === null || curUser === null || publicTodoEvents === null) {
    return (
      <div></div>
    )
  }

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <div class="container mx-auto px-4 md:px-8 w-full lg:w-1/2">
      {
        (myUser && effectiveUserId() === myUser.myUserInfo.myUserId) ?
          // Me
          <div class="container mx-auto">
            <LinkInput setOutsideTextInput={setLikeInput} />
            <div class="flex items-center justify-center">
              <button type="submit" onClick={handleLike} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">{localizeKey('like', language)}</button>
            </div>
          </div>
        :
          // Other user
          <div class="container mx-auto">
            <TextUserPair user={curUser} />
          </div>
      }

      {
        publicTodoEvents && publicTodoEvents.length > 0 &&
        <div class="mt-3">
          <div class="text-center">
            {localizeKey('publicTodos', language)}:
          </div>
          <ul className="container mx-auto divide-y divide-gray-100">
            {publicTodoEvents.map((publicTodo, index) => (
              <li>
                <CardEventArtChat event={publicTodo.todo} eventType="todo" art={publicTodo.art} language={language} misc={
                  {
                    publishTodo: null // do not allow change publicity on main user page
                  }
                }/>
              </li>
            ))}
          </ul>
        </div>
      }

      <div class="text-center">
        {localizeKey('likes', language)}:
      </div>
      <ul className="container mx-auto divide-y divide-gray-100">
        {likeEvents.map((like, index) => (
          <li>
            <CardEventArtChat event={like.like} eventType="like" art={like.art} language={language} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LikeEvents;