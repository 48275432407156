import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import ShareButton from './ShareButton';
import CopyTextButton from './CopyTextButton';
import TagsInput from './TagsInput';
import { Link } from 'react-router-dom';

// Why eventType is not part of event?
// Why userName and userInfo are separate?
// Why chatInfo and chatState are separate?
// What's in misc?
const CardEventArtChat = ({ event, eventType, art, chatInfo, language, userName, userInfo, misc, chatState }) => {
  const myUser = useContext(MyUserContext);

  // console.log("CardEventArtChat, myUser: " + myUser.myUserInfo + " , myUserId: " + myUser.myUserInfo.myUserId + " , misc.fromUser: " + misc.fromUser + " , misc.fromUser.id " + misc.fromUser.id);
  const artLink = art.wikiArticleLink;
  const hostnameParts = new URL(artLink).hostname.split('.');
  const secondLevelDomain = hostnameParts[1];
  let iconFile = "/wiki_icon.png";
  if (secondLevelDomain === 'steampowered') {
    iconFile = "/steam_icon.png";
  } else if (secondLevelDomain == 'imdb') {
    iconFile = "/imdb_icon.png"
  } else if (secondLevelDomain == 'apple') {
    iconFile = "/applebooks_icon.png"
  }

  const elementRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          axiosInstance
          .get(`/artEvents/${event.id}`) // TODO: make a separate light-weight endpoint!
          .then((response)=>{
            // Do nothing, ntf is marked as read.
          })
          .catch((error) => {
            console.error("Failed to mark notification as read: " + error.message);
          });
        }
      },
      {
        threshold: 0.1, // Adjust the threshold as needed
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  const [myEventType, setMyEventType] = useState(art.myEventType);
  const [eventId, setEventId] = useState(event.id);
  const [eventTags, setEventTags] = useState(event.eventTags);

  const handleEventTypeMove = (newEventType) => {
    axiosInstance
    .post(`/artEvents`, { artId: art.id, type: newEventType })
    .then((response) => {
      setMyEventType(newEventType); // todo: get rid of separate event type
      //console.log("response.data.event.id: " + response.data.event.id);
      setEventId(response.data.event.id);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const [removed, setRemoved] = useState(false);
  const handleEventRemove = (eventId) => {
    axiosInstance
    .delete(`/artEvents/${eventId}`)
    .then((response) => {
      //setRemoved(true); // todo: remove
      setPinned(false); // it's so ugly I cry
      setMyEventType("none"); // SO UGLY, just use response from the server to update art
    })
    .catch((error) => {
      console.error(error);
    })
  }

  const handleEventTag = (eventId, inputTagNames) => {
    axiosInstance
    .post(`/artEvents/tag/${eventId}/tag`, { tags: inputTagNames })
    .then((response) => {
      setEventTags(response.data.event.eventTags);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  // TODO: probably better use event state common for all its fields.
  const [pinned, setPinned] = useState(event.pinned);
  const handleEventPin = (eventId) => {
    axiosInstance
    .post(`/artEvents/pin/${eventId}/pin`)
    .then((response) => {
      setPinned(true);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 406) {
          alert(localizeKey('pinsLimitReached', language));
        } else {
          console.error(error);
        }
      } else {
        console.error(error);
      }
    })
  }
  const handleEventUnpin = (eventId) => {
    axiosInstance
    .delete(`/artEvents/pin/${eventId}/pin`)
    .then((response) => {
      setPinned(false);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  // console.log("chatInfo in CardEventArtChat " + chatInfo);

  // console.log("Constructing CardEventArtChat with chat id " + event.chatId + " with chatMsgCount " + chatMsgCount);

  // if (chatMsgCount === null) {
  //   // fetchChatMsgCount();
  // }

  const recomNotFinished = eventType === "recommendation" && art.myEventType !== "like" && art.myEventType !== "finished";
  let outerClassModification = "";
  if (false && removed) { // todo: refactor
    outerClassModification = "pointer-events-none grayscale";
  } else if (recomNotFinished) {
    outerClassModification = "border border-blue-400 rounded-lg shadow outline-1";
  }

  console.log("userInfo: " + JSON.stringify(userInfo, null, 4));

  return (
    <>
      <div ref={elementRef} class={(outerClassModification !== "" ? outerClassModification + " " : "") + "mx-auto my-4 max-w-xl"}>
        <div class="mx-auto w-auto flex flex-row items-center bg-white border border-gray-200 rounded-lg shadow max-w-xl">
          <Link to={"/arts/"+art.id} >
            <img class="object-cover rounded-t-lg h-auto min-w-20 w-20 md:min-w-40 md:w-40 rounded-none rounded-s-lg" src={art.thumbnailUrl} alt=""/>
          </Link>
          <div>

          {
            //(eventType !== "none" && event.userID !== myUser.myUserId) &&
            <div class="text-lg ml-2 md:ml-4">
              {
              (eventType !== "recommendation") &&
              <div class="bg-white flex text-sm items-center mt-1">
                <img class="rounded-full aspect-square max-w-8 max-h-8" src={userInfo.avatarUrl}/>
                {/*
                <span class="hover:cursor-pointer text-blue-500 hover:text-blue-800">
                  <Link to={`/users/${event.userID}/likes`}>
                    {userInfo.name + " "}
                  </Link>
                </span>
                */}
                <b>
                  <Link to={`/users/${event.userID}/likes`}>
                    &nbsp;
                    <span class="hover:underline">
                      {myUser.myUserInfo.myUserId === event.userID ? "я" : userInfo.name}
                    </span>
                  </Link>
                  &nbsp;{localizeKey(eventType+'_did', language)}
                </b>
              </div>
              }
              <div class="flex items-center text-base md:text-lg leading-none -mt-1">
                <span>{localizeArt(art, language).name}</span>&#32;
                
                <a href={localizeArt(art, language).wikiLink} target="_blank" rel="noopener noreferrer" className="flex items-center">
                  <img
                    src={iconFile}
                    alt="Source"
                    className="max-w-20 max-h-8 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
                  />
                </a>
                  {
                    myUser && event.userID === myUser.myUserInfo.myUserId ?
                      <>
                        {
                        myEventType !== "none" &&
                        <>
                          {
                            pinned ?
                              <div class="ml-2 md:ml-4 cursor-pointer text-2xl" onClick={()=>{handleEventUnpin(eventId)}}>
                                📌
                              </div>
                            :
                              <div class="ml-2 md:ml-4 cursor-pointer text-2xl grayscale" onClick={()=>{handleEventPin(eventId)}}>
                                📌
                              </div>
                          }
                        </>
                        }
                      </>
                    :
                      <>
                        {
                          pinned &&
                          <div class="ml-2 md:ml-4 text-2xl">
                            📌
                          </div>
                        }
                      </>
                  }
                
              </div>
              <div class="-mt-3">
                <a class="text-sm text-gray-400 hover:underline" href={"https://wowcontent.lol/" + (eventType === "recommendation" ? "recommendations/" : "artEvents/") + event.id}>
                  {new Date(event.createdTime).toLocaleDateString(language, { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
                </a>
              </div>              
            </div>
          }

            <div>
              {
                eventType === 'recommendation' &&
                (
                  myUser && misc.fromUser.id === myUser.myUserInfo.myUserId ? // myUser && is to allow non-logged-in which is unnecessary
                    <div class="flex items-center">
                      {localizeKey('toWhomRecommended', language)}:&nbsp;
                      <Link class="flex items-center" to={`/users/${misc.toUser.id}/likes`}>
                        <img class="rounded-full max-w-8 max-h-8" src={misc.toUser.avatarUrl}/>
                        &nbsp;
                        <span class="text-blue-500">
                          {misc.toUser.userName}
                        </span>
                      </Link>
                    </div>
                  :
                    <div class="flex items-center">
                      {localizeKey('byWhomRecommended', language)}:&nbsp;
                      <Link class="flex items-center" to={`/users/${misc.fromUser.id}/likes`}>
                        <img class="rounded-full max-w-8 max-h-8" src={misc.fromUser.avatarUrl}/>
                        &nbsp;
                        <span class="text-blue-500">
                          {misc.fromUser.userName}
                        </span>
                      </Link>
                    </div>
                )
              }
            </div>

            <div class="flex text-sm md:text-base ml-2 md:ml-4">
              {
                (art.myFollowees.likeUsers || art.myFollowees.todoUsers || art.myFollowees.finishedUsers) &&
                localizeKey('othersReaction', language) + ": "                
              }
              
              {
              art.myFollowees.likeUsers && 
                art.myFollowees.likeUsers.map(
                  (u, index) => {
                    return (
                      <Link class="w-6 h-6 rounded-full" to={`/users/${u.id}/likes`}>
                        <img
                          src={u.avatarUrl}
                          style={{'margin-left': '-'+ index*4 +'px'}}
                          class={"w-6 h-6 rounded-full"}
                        />
                      </Link>
                    )
                  }
                )
              }
              {
              art.myFollowees.todoUsers &&               
                art.myFollowees.todoUsers.map(
                  (u, index) => {
                    return (
                      <Link class="w-6 h-6 rounded-full" to={`/users/${u.id}/likes`}>
                        <img
                          src={u.avatarUrl}
                          style={{'margin-left': '-'+ index*4 +'px'}}
                          class={"w-6 h-6 rounded-full"}
                        />
                      </Link>
                    )
                  }
                )
              }
              {
              art.myFollowees.finishedUsers && 
                art.myFollowees.finishedUsers.map(
                  (u, index) => {
                    return (
                      <Link class="w-6 h-6 rounded-full" to={`/users/${u.id}/likes`}>
                        <img
                          src={u.avatarUrl}
                          style={{'margin-left': '-'+ index*4 +'px'}}
                          class={"w-6 h-6 rounded-full"}
                        />
                      </Link>
                    )
                  }
                )             
              }
            </div>          

          {/*Tags*/
          (myUser && event.userID === myUser.myUserInfo.myUserId) &&
            <TagsInput initialTags={eventTags ? eventTags.map((eventTag) => { return eventTag.name; }) : []} onTagsChange={(updatedTags) => {handleEventTag(eventId, updatedTags)}} />
          }

          <div class="ml-2 md:ml-4"> {/* ugly workaround, TODO: get rid of ml-x here */}
            <div>
              {language === "ru" ? "Добавлено у меня:" : "Added to mine:"}
            </div>
            <div class="flex space-x-1 justify-center items-center">
              <input type="radio" name={"same"+event.id} id="like" onClick={()=>{handleEventTypeMove("like")}} checked={myEventType === "like" ? "checked" : ""}/>
              <label for="like">{localizeKey('likes', language)}</label>
              <input type="radio" name={"same"+event.id} id="todo" onClick={()=>{handleEventTypeMove("todo")}} checked={myEventType === "todo" ? "checked" : ""}/>
              <label for="todo">{localizeKey('todos', language)}</label>
              <input type="radio" name={"same"+event.id} id="finished" onClick={()=>{handleEventTypeMove("finished")}} checked={myEventType === "finished" ? "checked" : ""}/>
              <label for="finished">{localizeKey('finisheds', language)}</label>
            </div>
          </div>

            {
              (myUser.myUserInfo.myUserId === event.userID && eventType !== 'recommendation' && myEventType !== "none") &&
              <div class="ml-2 md:ml-4 cursor-pointer text-red-500 hover:text-red-800" onClick={()=>{handleEventRemove(eventId)}}> {(language === "ru" ? "Удалить из моего списка" : "Remove from my list")} </div>
            }
          </div>
        </div>
        {
        chatState !== "hidden" &&
        <div>
          <Collapsible collapsed={chatState === "shown" ? false : /*collapsed*/ true } buttonText={ localizeKey('chat', language) + " (" + chatInfo.messageCount + " " + localizeKey('messages', language) + ")" }>
            <MyChatComponent chatId={event.chatId} userChatInfo={chatInfo} language={language} myUserInfo={myUser ? myUser.myUserInfo : null} chatOwnerInfo={{type:eventType, id: event.id}}/>
          </Collapsible>
        </div>
        }
      </div>
    </>
  );
}

export default CardEventArtChat;