import axios from 'axios';

const env = "production";
//const env = "localhost";

let baseURL = 'https://api.wowcontent.lol';
let baseWebSocketURL = 'wss://api.wowcontent.lol';
if (env === "localhost") {
    baseURL = 'http://localhost:8081';
    baseWebSocketURL = 'ws://localhost:8081';
}
export {baseURL};
export {baseWebSocketURL};

const axiosInstance = axios.create({
    baseURL,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwtToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;