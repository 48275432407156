import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import ShareButton from './ShareButton';
import CopyTextButton from './CopyTextButton';
import PersonalRecommendationUsers from './PersonalRecommendationUsers';
import { Link } from 'react-router-dom';

const ArtPage = ({ _art }) => { // oneOf: art, artId
  const myUser = useContext(MyUserContext);
  const { id } = useParams();

  const [art, setArt] = useState(_art);
  if (_art && art.id !== _art.id) { // it's an ugly hack since the old art is rendered first anyway :(
    setArt(_art);
  }

  const fetchArt = () => {
    axiosInstance
    .get(`/arts/${id}/`)
    .then((response) => {
      setArt(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const handleEventTypeMove = (newEventType) => {
    axiosInstance
    .post(`/artEvents`, { artId: art.id, type: newEventType })
    .then((response) => {
      // setArt((previous) => ({...previous, myEventType: newEventType}));
      setArt(response.data.art);
      // setMyEventType(newEventType); // todo: get rid of separate event type
      //console.log("response.data.event.id: " + response.data.event.id);
      // setEventId(response.data.event.id);
    })
    .catch((error) => {
      console.error(error);
    });
  };

  if (art === undefined || art == null) {
    fetchArt();
    return (
      <>
      </>
    );
  }

  // TODO: remove this code duplication
  const artLink = art.wikiArticleLink;
  const hostnameParts = new URL(artLink).hostname.split('.');
  const secondLevelDomain = hostnameParts[1];
  let iconFile = "/wiki_icon.png";
  if (secondLevelDomain === 'steampowered') {
    iconFile = "/steam_icon.png";
  } else if (secondLevelDomain == 'imdb') {
    iconFile = "/imdb_icon.png"
  } else if (secondLevelDomain == 'apple') {
    iconFile = "/applebooks_icon.png"
  }

  const language = myUser !== null ? myUser.myUserSettings.lang : 'ru';

  return (
    <div class="divide-y container mx-auto mt-4 md:mt-8 px-4 md:px-8 w-full lg:w-1/2">
      <div>
        <div class="flex justify-center">
          <img class="max-h-96" src={art.thumbnailUrl} />
        </div>
        <div class="flex justify-center text-3xl mt-2 md:mt-4">
          <b>{localizeArt(art, language).name}</b>
          <a href={localizeArt(art, language).wikiLink} target="_blank" rel="noopener noreferrer" className="flex items-center">
            <img
              src={iconFile}
              alt="Source"
              className="max-w-20 max-h-8 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
            />
          </a>
        </div>
        <div class="flex justify-center text-center">
          {localizeArt(art, language).description}
        </div>
        <div class="flex justify-center">
          {localizeArt(art, language).videoGameDeveloper}
        </div>
      </div>

      <div class="mt-4 mb-4 text-xl">
        <div>
          {
          art.otherPeopleEvents ?
            <>
              <div class="mt-4 flex justify-center">
                {language === "ru" ? "Добавлено у других:" : "Other people added:"}
              </div>
              <div class="flex justify-center">
                <div class="grid grid-cols-5 gap-1">
                  {art.otherPeopleEvents.map(
                    (ev, index) => {
                      return (
                        <div>
                          <Link to={`/artEvents/${ev.artEventId}`}>
                            <img
                              src={ev.userAvatarUrl}
                              class={"w-12 h-12 rounded-full"}
                            />
                          </Link>                        
                        </div>
                      )
                    }
                  )}
                </div>
              </div>
            </>
          :
            <div class="mt-4 flex justify-center text-center">
              {language === "ru" ? "Другие пользователи пока не добавили себе" : "Other people haven't added it yet"}
            </div>
          }
        </div>

        <div>
          {
          art.myEventType ?
            <div class="mt-4 flex justify-center">
              {language === "ru" ? "Добавлено у меня:" : "Added to mine:"}
            </div>
          :
            <div class="mt-4 flex justify-center">
              {language === "ru" ? "Добавить ко мне:" : "Add to mine:"}
            </div>
          }
          <div class="flex justify-center items-center space-x-3">
            <div class="cursor-pointer space-x-1">
              <input type="radio" name="same" id="like" checked={art.myEventType === "like" ? "checked" : ""}/>
              <label class="text-blue-500 hover:text-blue-800 cursor-pointer" for="like" onClick={()=>{handleEventTypeMove("like")}} >{localizeKey('likes', language)}</label>
            </div>
            <div class="cursor-pointer space-x-1">
              <input type="radio" name="same" id="todo" onClick={()=>{handleEventTypeMove("todo")}} checked={art.myEventType === "todo" ? "checked" : ""}/>
              <label class="text-blue-500 hover:text-blue-800 cursor-pointer" for="todo">{localizeKey('todos', language)}</label>
            </div>
            <div class="cursor-pointer space-x-1">
              <input type="radio" name="same" id="finished" onClick={()=>{handleEventTypeMove("finished")}} checked={art.myEventType === "finished" ? "checked" : ""}/>
              <label class="text-blue-500 hover:text-blue-800 cursor-pointer" for="finished">{localizeKey('finisheds', language)}</label>
            </div>
          </div>
          <div class="flex text-center justify-center text-sm text-slate-500">
            {language === "ru" ? "Любимое - ваше избранное, к чему, возможно, захочется вернуться" : "Favorites - you'll probably want to check it out again"}
            <br/>            
            
            {language === "ru" ? "Планы - ваши... планы, чтобы не забыть " : "Todos - your plans"}
            <br/>
            
            {language === "ru" ? "Ознакомлен(а) - вы просто посмотрели, прочитали или поиграли в это (не заслуживает быть в любимом)" : "Checked out - you checked it out but it's not worth to be in your favorites"}
            <br/>
          </div>
          {
          (art.myEventType === "like" || art.myEventType === "todo" || art.myEventType === "finished") &&
          <div class="flex justify-center">
            <Link class="cursor-pointer text-blue-500 hover:text-blue-800" to={"/artEvents/"+art.myEventId}>
              {language === "ru" ? "Перейти к добавленному" : "Go to my 'added'"}
            </Link>
          </div>
          }
        </div>
      </div>

      <div class="mt-4 mb-8 text-xl">
        <PersonalRecommendationUsers art={art} />
      </div>
    </div>
  );
}

export default ArtPage;