import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import CardEventArtChat from './CardEventArtChat';
import LinkInput from './LinkInput';
import TextUserPair from './TextUserPair';
import CopyTextButton from './CopyTextButton';
import DotSpinner from './DotSpinner';
import Tabs from './Tabs';
import ArtPage from './ArtPage';
import PersonalRecommendationUsers from './PersonalRecommendationUsers';
import getIconForLink from '../iconForLink.js';
import { Link } from 'react-router-dom';

const AddEvent = () => {
  const myUser = useContext(MyUserContext);
  const navigate = useNavigate();

  const [art, setArt] = useState(null);
  const [addedEvents, setAddedEvents] = useState([]);
  // const [successSign, setSuccessSign] = useState(null); // like, todo, finished

  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);
  //const [foundArts, setFoundArts] = useState([]);

  // const [foundArtsByType, setFoundArtsByType] = useState(new Map());

  const [foundMovies, setFoundMovies] = useState(null);
  const [foundBooks, setFoundBooks] = useState(null);
  const [foundGames, setFoundGames] = useState(null);


  const handleSearchArt = (term, artType) => {
    if (term === "") {
      return;
    }
    setSearching(true);
    setFoundMovies(null);
    setFoundGames(null);
    setFoundBooks(null);
    setActiveTab('movie');
    // setAddedEvents([]);
    setArt(null);
    // setFoundArtsByType(map => new Map(map.set(artType, [])));
    // console.log(foundArtsByType);
    axiosInstance
    .get(`/arts/search?term=${term}&artType=${artType}`)
    .then((response) => {
      setSearching(false);
      //setFoundArts(response.data);
      //console.log("for "+artType + ": " + response.data);

      if (addedEvents.length > 0) { // user already clicked on something found earlier so skip this result
        return;
      }

      if (artType === "movie") {
        setFoundMovies(response.data);
      } else if (artType === "book") {
        setFoundBooks(response.data);
      } else { // videoGame
        setFoundGames(response.data);
      }
      //setFoundArtsByType(map => new Map(map.set(artType, response.data)));
    })
    .catch((error) => {
      console.error(error);
      setSearching(false);
      //setFoundArts([]);
      if (error.response.status === 400) {
        alert(error.response.data);
      }
    })
  };
  // const handleFoundArtClick = (link, eventType) => {    
  //   axiosInstance
  //   .post(`/artEvents`, { wikiArticleLink: link, type: eventType })
  //   .then((response) => {
  //     // setAddedEvents(prevObjects => [response.data, ...prevObjects]); // not sure
  //     setAddedEvents([response.data]); // not sure
  //     // setLinkInput(""); // not sure
  //     // setSuccessSign(eventType);
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //     // setSuccessSign(null);
  //     // setLinkInputErr(true);
  //   });
  // }

  // const handleLinkSubmit = (eventType, link) => {
  //   axiosInstance
  //   .post(`/artEvents`, { wikiArticleLink: link, type: eventType })
  //   .then((response) => {
  //     // setAddedEvents(prevObjects => [response.data, ...prevObjects]); // not sure
  //     setFoundMovies(null);
  //     setFoundGames(null);
  //     setFoundBooks(null);
  //     setActiveTab('movie');


  //     setAddedEvents([response.data]); // not sure
  //     // setLinkInput(""); // not sure
  //     // setSuccessSign(eventType);
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //     // setSuccessSign(null);
  //     // setLinkInputErr(true);
  //   });
  // };
  const handleArtSubmit = (link) => {
    axiosInstance
    .post(`/arts`, { wikiArticleLink: link })
    .then((response) => {
      // setAddedEvents(prevObjects => [response.data, ...prevObjects]); // not sure
      // setFoundMovies(null);
      // setFoundGames(null);
      // setFoundBooks(null);
      // setActiveTab('movie');
      //setArt(response.data);
      navigate("/arts/"+response.data.id);
      // setLinkInput(""); // not sure
      // setSuccessSign(eventType);
    })
    .catch((error) => {
      console.error(error);
      // setSuccessSign(null);
      // setLinkInputErr(true);
    });
  };

  const [activeTab, setActiveTab] = useState('movie');

  const eventTypeStr = (i) => {
    const m = {
      0: "none",
      1: "finished",
      2: "todo",
      3: "like"
    };
    return m[i];
  }

  const language = myUser !== null ? myUser.myUserSettings.lang : 'ru';

  return (
    <div class="container mx-auto px-4 md:px-8 w-full">
      {
      art &&
      <div id={art.id} class="flex justify-center text-center border-b-2 border-blue-500">
        <ArtPage id={art.id} _art={art} />
      </div>
      }
      
      <div class="mt-3">        
        <div class="flex justify-center items-center space-x-3">          
          <a href={language === "ru" ? "https://ru.wikipedia.org" : "https://en.wikipedia.org" } target="_blank" rel="noopener noreferrer" className="flex items-center">
            <img
              src="/wiki_icon.png"
              alt="Wikipedia"
              className="max-w-8 max-h-8 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
            />
          </a>

          <a href="https://imdb.com/" target="_blank" rel="noopener noreferrer" className="flex items-center">
            <img
              src="/imdb_icon.png"
              alt="Imdb"
              className="max-w-12 max-h-12 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
            />
          </a>

          <a href={language === "ru" ? "https://www.google.ru/search?q=apple+books+com+НАЗВАНИЕ_КНИГИ" : "https://www.google.com/search?q=apple+books+com+YOUR_BOOK_TITLE" } target="_blank" rel="noopener noreferrer" className="flex items-center">
            <img
              src="/applebooks_icon.png"
              alt="Apple books"
              className="max-w-12 max-h-12 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
            />
          </a>

          <a href="https://store.steampowered.com/" target="_blank" rel="noopener noreferrer" className="flex items-center">
            <img
              src="/steam_icon.png"
              alt="Steam"
              className="max-w-12 max-h-12 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80 cursor-pointer"
            />
          </a>
        </div>
      </div>

      <div class="flex justify-center mt-1">
        <input class="lg:w-1/2 h-10 bg-gray-50 w-full border rounded-lg text-center" type="text" id="art_search" placeholder={language === "ru" ? "Название или ссылка на произведение" : "Art name or a direct link to it" }
          onKeyDown={
            (e) => {
              if (e.key === 'Enter') {
                if (e.target.value.startsWith("https://")) {
                  handleArtSubmit(e.target.value);
                } else {
                  handleSearchArt(e.target.value, "movie");
                  handleSearchArt(e.target.value, "book");
                  handleSearchArt(e.target.value, "videoGame");
                }
              }
            }
          }
          onChange={
            (e) => {
              setSearchTerm(e.target.value);
            }
          }
          autofocus
        />
      </div>

      <div class="flex justify-center mt-5 text-blue-500 hover:text-blue-800 hover:cursor-pointer" onClick={
        (e) => {
          const ruStrings = ['игра престолов', 'интерстеллар', 'бойцовский клуб', 'властелин колец', 'криминальное чтиво', 'гарри поттер'];
          const enStrings = ['game of thrones', 'interstellar', 'fight club', 'lord of the rings', 'pulp fiction', 'harry potter'];
          const strings = (language === "ru") ? ruStrings : enStrings;
          const randomIndex = Math.floor(Math.random() * strings.length);
          const text = strings[randomIndex];
          handleSearchArt(text, "movie");
          handleSearchArt(text, "book");
          handleSearchArt(text, "videoGame");
        }
      }>
        {language === "ru" ? "Показать случайное произведение" : "Show random art"}
      </div>

      {/*
      addedEvents.length > 0 &&
      <>
        <ul className="container mx-auto divide-y divide-gray-100">
          {addedEvents.map((addedEvent, index) => (
            <li class="container mx-auto">
              <CardEventArtChat chatState="hidden" event={addedEvent.event} chatInfo={addedEvent.chat} eventType={eventTypeStr(addedEvent.event.type)} art={addedEvent.art} language={language} />
            </li>
          ))}
        </ul>
        <PersonalRecommendationUsers art={addedEvents[0].art} />
      </>
      */}

      {
      (foundMovies || foundBooks || foundGames) &&
      <div class="mt-1">
        <Tabs counters={{"movie": foundMovies, "videoGame": foundGames, "book": foundBooks}} onTabChange={(input) => {
          setActiveTab(input);
          //handleSearchArt(searchTerm, input);
        }} />
      </div>
      }

      {
        searching && 
        <div class="flex justify-center"> 
          <DotSpinner />
        </div>
      }

      <ul className="container mx-auto divide-y divide-gray-100">
        {(activeTab === "movie" && foundMovies) && foundMovies.map((foundArt, index) => (
          <li id={foundArt.pageUrl} class="mb-3 mt-3 container mx-auto">
            <div class="flex justify-center items-center"> 
              {language === "en" ? foundArt.enName : foundArt.ruName}
              <div class="ml-2">
                <a href={foundArt.pageUrl} target="_blank">
                  <img class="max-w-12 max-h-12 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80" src={getIconForLink(foundArt.pageUrl)} />
                </a>
              </div>
            </div>
            <div class="flex justify-center" > 
              <img fetchpriority={index < 3 ? "high" : "low"} loading="lazy" class="max-w-40 cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80" src={foundArt.imageUrl} onClick={()=>{
                // setLinkInput(foundArt.pageUrl);
                handleArtSubmit(foundArt.pageUrl);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}/>     
            </div>
          </li>
        ))}

        {(activeTab === "videoGame" && foundGames) && foundGames.map((foundArt, index) => (
          <li id={foundArt.pageUrl} class="mb-3 mt-3 container mx-auto">
            <div class="flex justify-center items-center"> 
              {language === "en" ? foundArt.enName : foundArt.ruName}
              <div class="ml-2">
                <a href={foundArt.pageUrl} target="_blank">
                  <img class="max-w-12 max-h-12 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80" src={getIconForLink(foundArt.pageUrl)} />
                </a>
              </div>
            </div>
            <div class="flex justify-center" > 
              <img fetchpriority={index < 3 ? "high" : "low"} loading="lazy" class="max-w-40 cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80" src={foundArt.imageUrl} onClick={()=>{
                // setLinkInput(foundArt.pageUrl);
                handleArtSubmit(foundArt.pageUrl);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}/>     
            </div>
          </li>
        ))}

        {(activeTab === "book" && foundBooks) && foundBooks.map((foundArt, index) => (
          <li id={foundArt.pageUrl} class="mb-3 mt-3 container mx-auto">
            <div class="flex justify-center items-center"> 
              {language === "en" ? foundArt.enName : foundArt.ruName}
              <div class="ml-2">
                <a href={foundArt.pageUrl} target="_blank">
                  <img class="max-w-12 max-h-12 transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80" src={getIconForLink(foundArt.pageUrl)} />
                </a>
              </div>
            </div>
            <div class="flex justify-center" > 
              <img fetchpriority={index < 3 ? "high" : "low"} loading="lazy" class="max-w-40 cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 hover:opacity-80" src={foundArt.imageUrl} onClick={()=>{
                // setLinkInput(foundArt.pageUrl);
                handleArtSubmit(foundArt.pageUrl);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}/>     
            </div>
          </li>
        ))}
      </ul>

    </div>
  );
}

export default AddEvent;